/**
 * ページ情報
 */
export const Routings = {
  search: {
    location: '/seller/assessment/search',
    title: 'マンション名検索',
  },
  condition: {
    location: '/seller/assessment/condition',
    title: '条件検索',
  },
  information: {
    location: '/seller/assessment/information',
    title: '個人情報入力',
  },
  result: {
    location: '/seller/assessment/result',
    title: '査定結果',
  },
  sellerInquiry: {
    location: '/seller/inquiry',
    title: '[売主]問合せ',
  },
  sellerCompleted: {
    location: '/seller/inquiry/completed',
    title: '問合せ完了',
  },
  login: {
    location: '/buyer/login',
    title: '買主会員ログイン',
  },
  buyerTop: {
    location: '/buyer',
    title: '買主TOP',
  },
  sellerTop: {
    location: '/seller',
    title: '売主TOP',
  },
  buyerAccount: {
    location: '/buyer/account',
    title: '会員情報',
  },
  buyerAccountEdit: {
    location: '/buyer/account/edit',
    title: '会員情報変更',
  },
  buyerAccountEditMail: {
    location: '/buyer/account/edit/mail',
    title: '会員メールアドレス変更',
  },
  buyerResetPassword: {
    location: '/buyer/account/reset-password',
    title: 'パスワード再登録',
  },
  buyerDeleteAccount: {
    location: '/buyer/account/delete',
    title: '退会のお手続き',
  },
  buyerDeleteAccountComplete: {
    location: '/buyer/account/delete/complete',
    title: '退会のお手続き',
  },
  buyerInquiry: {
    location: '/buyer/inquiry',
    title: '[買主]問合せ',
  },
  buyerCompleted: {
    location: '/buyer/inquiry/completed',
    title: '問合せ完了',
  },
  accountRegistration: {
    location: '/buyer/account/registration',
    title: '会員登録',
  },
  terms: {
    location: '/terms',
    title: '利用規約',
  },
  privacy: {
    location: '/privacy',
    title: 'プライバシーポリシー',
  },
  buyerProperty: {
    location: '/buyer/property',
    title: '物件詳細',
  },
} as const;

/**
 * 外部ページ情報
 */
export const ExternalRoutings = {
  worksBae: 'https://works-bae.co.jp/',
} as const;
