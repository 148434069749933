import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Routings } from '@/common/routings';
import withInitializeApp from '@/utils/hoc/withInitializeApp';

const IndexPage = () => {
  useEffect(() => {
    // 買主トップに遷移
    navigate(Routings.buyerTop.location);
  }, []);

  return null;
};

export default withInitializeApp(IndexPage);
